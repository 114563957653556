exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-tsx": () => import("./../../../src/pages/en.tsx" /* webpackChunkName: "component---src-pages-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lahjakortit-tsx": () => import("./../../../src/pages/lahjakortit.tsx" /* webpackChunkName: "component---src-pages-lahjakortit-tsx" */),
  "component---src-pages-tapahtumat-tsx": () => import("./../../../src/pages/tapahtumat.tsx" /* webpackChunkName: "component---src-pages-tapahtumat-tsx" */),
  "component---src-pages-varaa-tsx": () => import("./../../../src/pages/varaa.tsx" /* webpackChunkName: "component---src-pages-varaa-tsx" */),
  "component---src-templates-eventpage-tsx": () => import("./../../../src/templates/eventpage.tsx" /* webpackChunkName: "component---src-templates-eventpage-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

